import Page from 'shared/ui/Page'
import styled from 'styled-components'
import NextImage from 'next/image'

import Genesis1 from 'public/static-geneses/1.png'
import Genesis2 from 'public/static-geneses/2.png'
import Genesis3 from 'public/static-geneses/3.png'
import Genesis4 from 'public/static-geneses/4.png'

const GenesisPics = [
  Genesis1,
  Genesis2,
  Genesis3,
  Genesis4
]

export default function Custom404() {
  return (
    <Page transparentHeader>
      <BackgroundImage>
        <NextImage src={GenesisPics[Math.floor(Math.random() * 4)]} layout="responsive" />
      </BackgroundImage>
      <Image src="/svg/mutagenbiglogo.svg" draggable="false" />
    </Page>
  )
}

const Image = styled.img`
  max-width: 100%;
  user-select: none;
  margin: 10rem auto 0;
  display: block;
  z-index: 1;
  position: relative;
`

const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
